export const RED = "#FD5061";
export const YELLOW ='#FFCEA5';
export const WHITE='white';
export const VINOUS='#A50710';
export const ORCHID='#00887A';
export const BLACK='#404144';
export const LAVANDER ="#FFF";
export const GREEN='#77a6f7';
export const BLUE='#114366'
export const GRAY='#5b6a75'
export const ORANGE='Tomato'
export const LIGHT_BLUE='rgb(3, 169, 245)'
export const PURPLE='#4e67eb'
export const DARK_BLUE='#291d89'
export const HEADING='#353755'
export const SUBHEADING='#5b6a75'




